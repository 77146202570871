import { Component, AfterViewInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inpost-widget',
  template: `
    <inpost-geowidget
      id="geowidget"
      [attr.token]="token"
      language="pl"
      config="parcelCollect"
      >
    </inpost-geowidget>
  `,
})

export class InpostWidgetComponent implements AfterViewInit {
  @Output() pointSelected = new EventEmitter<string>(); // Emiter do wysyłania ID wybranego punktu

  token: string = environment.inpostWidgetToken;

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.loadInpostWidgetScript();
  }

  private loadInpostWidgetScript(): void {
    const script = this.renderer.createElement('script');
    script.id = 'inpost-widget-script'; // Ustaw identyfikator dla łatwego odnalezienia
    script.src = 'https://geowidget.inpost.pl/inpost-geowidget.js';
    script.defer = true;

    script.onload = () => {
      const geowidget = document.getElementById('geowidget');

      // Nasłuchuj zdarzenia inpost.geowidget.init
      if (geowidget) {
        geowidget.addEventListener('inpost.geowidget.init', (event: any) => {
          this.reloadPageIfNessesary();
          const api = event.detail.api;
          api.addPointSelectedCallback((point: any) => {
            console.log('Wybrano punkt:', point);
            this.pointSelected.emit(point.name); // Emituj ID wybranego punktu do komponentu nadrzędnego
          });
          // api.changePosition({ longitude: 17.90529, latitude: 53.23444 }, 16);
          const lon = 18.008438;
          const lat = 53.123480;
          api.changePosition({ longitude: lon, latitude: lat }, 11);
          console.log("Zmieniono koordynaty na mapie na " + lon + ':' + lat);
        });
      }
    };
    document.body.appendChild(script);
  }

  private reloadPageIfNessesary(): void {
    const iframe = document.getElementById('geowidget-v5-iframe-render') as HTMLIFrameElement;

    if (iframe) {
      const currentSrc = iframe.src;

      // Sprawdź, czy token jest 'null' i zamień go na właściwy token
      if (currentSrc.includes('token=null')) {
        console.log('Pusty token widgetu, reload');
        window.location.reload();
      } else {
        console.log("Token nie jest 'null', nie trzeba przeładowywać");
      }
    } else {
      console.log("Nie znaleziono iframe o id 'geowidget-v5-iframe-render'");
    }
  }
}
