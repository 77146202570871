declare var require: any;
export const environment = {
  name: 'test',
  email: 'info@test.martynaklewinowska.pl',
  version: require('../../package.json').version,
  production: false,
  backendDelay: 0,
  httpRetry: 0,
  lazyLoadingImageSaveInMemory: true,
  loggingBackendUrl: false,
  loggingBackendRequestHeaders: false,
  loggingBackendRequest: false,
  loggingBackendResponse: false,
  apiEndpoint: 'https://sklep.test.martynaklewinowska.pl/api',
  messageDismissible: false,
  messageSuccessTimeout: 1000 * 10,
  messageInfoTimeout: 1000 * 20,
  messageAnotherTimeout: 1000 * 60,
  allowedDomains: ["sklep.martynaklewinowskatst.pl"],
  disallowedRoutes: [] as string[],
  urlContact: 'https://www.martynaklewinowska.pl/kontakt', // blank: '#'
  urlBlog: 'https://www.martynaklewinowska.pl/blog',
  urlRegulation: 'https://sklep.martynaklewinowska.pl/resource/sklep.regulamin.pdf',
  urlPolicy: 'https://sklep.martynaklewinowska.pl/resource/sklep.polityka.prywatnosci.pdf',
  urlFacebook: 'https://www.facebook.com/martynaklewinowska',
  urlInstagram: 'https://www.instagram.com/martynaklewinowska/',
  blikEnabled: true,
  inpostWidgetEnabled: true,
  inpostWidgetToken: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwNDU2ODM1MTYsImlhdCI6MTczMDMyMzUxNiwianRpIjoiNWQyNzA4ZTctZTM3Yi00Yjg1LWEwYTAtZDQzMTBhMjM2NzUxIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTpvMXdNYy1EbmlkM3JHMDYya2NudGYyZ0NOUUt6VHRRdXNfRFRxczJhT3NjIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiMjc2ZTM1ODMtZjYxOS00YjFhLTgxM2EtNzM4NWNjYjFlMjYxIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6IjI3NmUzNTgzLWY2MTktNGIxYS04MTNhLTczODVjY2IxZTI2MSIsImFsbG93ZWRfcmVmZXJyZXJzIjoic2tsZXAudGVzdC5tYXJ0eW5ha2xld2lub3dza2EucGwiLCJ1dWlkIjoiZWFiMzQ5Y2QtNmNjMy00NWU1LWJhN2YtNWM3MjliZDRjNTI5In0.clIjj_3lloqsPRkvEBCcR13Hsm6RBM5J24ABU_kRUgtISspwUl-ib6L6mJV-_N3qP8-Gh0WhvoAAR1S7zG6-DdBXjIdcX_vBLkfYPUgF0K56_9T-ug7Gai1c7wcMWjbTU5VOcgL6gNZ8DQT6W9XAc8sYmf34LhJcgGCuhN0cIx_N0R58orBdtH9MbJuJExUd3q8eBsNF8TNvhsLMvEAbI8yQCXefQoNn_WvihymukXmlIIdPPUCCpy7CkmVP0QyJ4XcG9sMBe2T9dTKWDawJkWL1b_bzxm6JoGTdiepk6jvHFrFjlTuBPIHMAYk-t3g7thAmE1NhysX1svx44ZsK_w',
};
